import React from "react"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"
import { withPreview } from "gatsby-source-prismic"
import SportsPost from "../components/Sports/SportsPost"
import { graphql } from "gatsby"
import preserveBreaks from "../utils/preserveBreaks"
import Layout from "../components/layout"
import ReactPlayer from "react-player"

const SportsPage = ({ data }) => {
  const posts = data.allPrismicSportsPost.nodes.sort((a, b) => a.data.order - b.data.order)

  const pageInfo = data.prismicSportsPage.data

  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="/sites/default/sports/css/fontawesome.min.css" />
        <link rel="stylesheet" href="/sites/default/sports/css/sports.css" />
      </Helmet>
      <Layout whiteLogo={true}>
        <SEO
          title={pageInfo.seo_title}
          description={pageInfo.seo_description}
          keywords={pageInfo.seo_keywords}
        />
        <div>
          <div className="darkbg">
            <div className="container header-container">
              <div className="top-content">
                <div className="text-wrapper">
                  <h1>{preserveBreaks(pageInfo.title.text)}</h1>
                  <p className="main-paragraph">{pageInfo.description}</p>
                  {pageInfo.button_link && !!pageInfo.button_link.url && (
                    <a
                      className="uk-button uk-button-primary uk-border-pill"
                      href={pageInfo.button_link.url}
                      target={pageInfo.button_link.target}
                    >
                      {pageInfo.button_label}
                    </a>
                  )}
                </div>
                <div className="media-wrapper">
                  {pageInfo.hero_video_url && pageInfo.hero_video_url.url ? (
                    <ReactPlayer
                      url={pageInfo.hero_video_url.url}
                      playing
                      playsinline
                      loop
                      controls={false}
                      muted={true}
                      width="100%"
                    />
                  ) : (
                    <img
                      src={pageInfo.hero_banner_image.fluid.src}
                      alt={pageInfo.hero_banner_image.alt}
                      style={{ backgroundPosition: "center" }}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="container">
              <div className="cards-wrapper">
                {posts.map(post => (
                  <SportsPost
                    key={post.data.order}
                    title={post.data.title.text}
                    url={post.data.button_link.url}
                    buttonText={post.data.button_text}
                    img={post.data.banner.fluid.src}
                    color={post.data.color}
                    order={post.data.order}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
export default withPreview(SportsPage)

export const roiQuery = graphql`
  {
    allPrismicSportsPost {
      nodes {
        data {
          banner {
            fluid(maxWidth: 600) {
              ...GatsbyPrismicImageFluid_noBase64
            }
          }
          button_link {
            url
          }
          button_text
          color
          order
          title {
            text
          }
        }
      }
    }
    prismicSportsPage {
      data {
        description
        seo_description
        seo_keywords
        seo_title
        title {
          text
        }
        button_link {
          target
          url
        }
        button_label
        hero_video_url {
          target
          url
        }
        hero_banner_image {
          alt
          fluid(maxWidth: 1400) {
            ...GatsbyPrismicImageFluid_noBase64
          }
          fixed(width: 600, imgixParams: { q: 20, blur: 80 }) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
      }
    }
  }
`
