import React, { useEffect, useRef } from "react"
import { Background } from "../VindiciaComponents"

const SportsPost = ({ order, title, color, buttonText, img, url }) => {
  const ref = useRef(null)
  useEffect(() => {
    if (ref.current) {
      ref.current.style.setProperty("--hover-color", color)
    }
  }, [])

  if (order < 10) order = "0" + order

  return (
    <div className="card">
      <Background className="card-inner" src={img} overlay={"rgba(0, 0, 0, 0.6)"}>
        <span className="enumerate" style={{ color }}>
          {order}
        </span>
        <div className="sliding_animation">
          <h2>{title}</h2>
          <a href={url} ref={ref} className="button" style={{ borderColor: color }}>
            {buttonText}
          </a>
        </div>
      </Background>
    </div>
  )
}

export default SportsPost
