import React from "react"

export default function preserveBreaks(text) {
  return text.split("\n").map((item, i) => {
    return (
      <React.Fragment key={i}>
        {item}
        <br />
      </React.Fragment>
    )
  })
}
